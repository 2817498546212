.navbar-item img {
  max-height: 3.75rem;
}

.box-list {
  padding-top: 0;
}

.box-hymn {
  padding-top: 1rem;
}